import React from 'react';
import MarketingLayout from '../../components/layout/MarketingLayout';
import PageHeader from '../../components/layout/PageHeader';
import Button from '../../components/ui/Button';
import PageSection from '../../components/ui/PageSection';
import Row from '../../components/ui/Row';
import Spacing from '../../components/ui/Spacing';

const ReferralConfirmationPage: React.SFC = () => (
  <MarketingLayout title="Refer friends to Slab">
    <Spacing bottom={5}>
      <PageHeader
        title="Thanks for referring your friend!"
        description="You get up to $200, and they’ll save 20% off their first 3 months. In the meantime, learn more about how our customers are benefiting from Slab."
      />
    </Spacing>
    <PageSection>
      <Spacing bottom={8}>
        <Row centered verticalCentered>
          <Button primary to="/customer-stories/">
            View Customer Stories
          </Button>
        </Row>
      </Spacing>
    </PageSection>
  </MarketingLayout>
);

export default ReferralConfirmationPage;
